import { useNavigate, Outlet } from 'react-router-dom';
import { useAuth } from './helpers/auth0';
import { useSelf } from './data/user';
import AppSidebar from '@/components/AppSidebar';
import AppHeader from '@/components/AppHeader';
import { Loading } from '@/components/ui/loading';

const App = () => {
  const navigate = useNavigate();
  const { isAuthenticated, isLoading, loginWithRedirect, authStrategy } = useAuth();

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    if (authStrategy === 'zebel-api') {
      navigate('/login');
    } else {
      loginWithRedirect();
    }
    return null;
  }

  return (
    <UserLoader>
      <AppHeader />
      <div className="min-h-[calc(100vh-48px)]">
        <AppSidebar />
        <main className="pl-[280px] max-w-[1400px] mx-auto">
          <div className="p-4">
            <Outlet />
          </div>
        </main>
      </div>
    </UserLoader>
  );
};

const UserLoader = ({ children }) => {
  const self = useSelf();

  if (self.isLoading && !self.data) {
    return <Loading />;
  }

  if (self.error) {
    return 'An error occurred loading user';
  }

  if (!self.data.roles.includes('zebeler')) {
    return 'You are not authorized to access this application.';
  }

  return children;
};

export default App;
