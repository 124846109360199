import axios from 'axios';
import Cookies from 'js-cookie';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCookieDomain } from './api';

const getAuth0Props = () => {
  if (window.location.hostname === 'admin.zebel.io') {
    return {
      domain: 'zebel.us.auth0.com',
      clientId: 'u4uRABrbYzu1SKcQkjMH5ZvNmDmXUCf8',
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: 'https://api.zebel.io'
      }
    };
  }

  if (window.location.hostname === 'admin.dev.zebel.io') {
    return {
      domain: 'zebel-dev.us.auth0.com',
      clientId: 'joSYCRkVvo3GaLOm0JxQKscpGj8g3nyr',
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: 'https://api.dev.zebel.io'
      }
    };
  }

  return {
    domain: 'zebel-dev.us.auth0.com',
    clientId: '7wgmvCRWSlGLAHG06VE12M8ijN9JoIlV',
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: 'https://app.eng.zebel.local:5000'
    }
  };
};

const useAuth = () => {
  // eslint-disable-next-line no-undef
  const authStrategy = process.env.VITE_AUTH_STRATEGY;
  const { isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently, logout, user } = useAuth0();
  const [isHttpLoading, setIsHttpLoading] = useState(true);
  const [isHttpReady, setIsHttpReady] = useState(false);

  let isAuthenticatedZebel = false;
  let zebelToken;
  if (authStrategy === 'zebel-api') {
    zebelToken = Cookies.get('token');
    if (zebelToken) {
      isAuthenticatedZebel = true;
    }
  }

  // console.log(isAuthenticatedZebel)

  useEffect(() => {
    const initializeHttp = async () => {
      axios.defaults.withCredentials = true;
      axios.interceptors.request.use(async (config) => {
        let apiToken;
        if (authStrategy !== 'zebel-api') {
          apiToken = await getAccessTokenSilently();
          apiToken = apiToken.replace(/-/g, '+').replace(/_/g, '/');
        }

        config.headers['Authorization'] = `Bearer ${isAuthenticatedZebel ? zebelToken : apiToken}`;
        return config;
      });

      setIsHttpReady(true);
      setIsHttpLoading(false);
    };

    if (!isLoading && !isHttpReady) {
      initializeHttp();
    }
  }, [isLoading, isHttpReady, getAccessTokenSilently, setIsHttpReady, setIsHttpLoading, authStrategy, isAuthenticatedZebel, zebelToken]);

  return {
    authStrategy,
    isAuthenticated: authStrategy === 'zebel-api' ? isAuthenticatedZebel : isAuthenticated,
    isLoading: isLoading || isHttpLoading,
    loginWithRedirect,
    logout,
    user
  };
};

const useLogoutUser = () => {
  const { logout, authStrategy } = useAuth();
  const navigate = useNavigate();

  const logoutUser = async () => {
    try {
      if (authStrategy === 'zebel-api') {
        Cookies.remove('token', { domain: getCookieDomain() });
        navigate('/login');
      } else {
        await logout();
      }
    } catch (err) {
      throw Error(err);
    }
  };

  return logoutUser;
};

export { getAuth0Props, useAuth, useLogoutUser };
