import { Link, Outlet, useHref, useParams } from 'react-router-dom';

import { Building2, Users } from 'lucide-react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from '@/components/ui/breadcrumb';
import { useCompany } from '@/data/company';
import { Loading } from '@/components/ui/loading';
import { Error } from '@/components/ui/error';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';

const Company = () => {
  const params = useParams();
  const company = useCompany(params.companyUid);
  const pathname = useHref();

  if (company.isLoading && !company.data) {
    return <Loading />;
  }

  if (company.error) {
    return <Error message="An error occurred loading company" />;
  }

  return (
    <>
      <Breadcrumb className="mb-4">
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink asChild>
              <Link to="/company">Companies</Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{company.data.company_name}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <Card>
        <CardHeader>
          <CardTitle>{company.data.company_name}</CardTitle>
          <CardDescription>{company.data.company_description}</CardDescription>
        </CardHeader>
        <CardContent>
          <Tabs value={pathname} className="w-full">
            <TabsList className="w-full">
              <TabsTrigger value={`/company/${params.companyUid}`} asChild>
                <Link to={`/company/${params.companyUid}`}>
                  <Building2 />
                  Company
                </Link>
              </TabsTrigger>
              <TabsTrigger value={`/company/${params.companyUid}/users`} asChild>
                <Link to={`/company/${params.companyUid}/users`}>
                  <Users />
                  Users
                </Link>
              </TabsTrigger>
            </TabsList>
          </Tabs>
          <div className="pt-4">
            <Outlet />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default Company;
