import axios from 'axios';
import useSWR from 'swr';
import { useState } from 'react';
import { resizeImage } from '@/helpers/document';
import { useToast } from '@/helpers/toast';

const useCompanies = () => {
  const url = `/admin/company`;
  const swr = useSWR(url, async (url) => {
    try {
      const result = await axios.get(url);
      return result.data;
    } catch (err) {
      throw Error(err);
    }
  });

  return swr;
};

const useCompany = (companyUid) => {
  const url = `/admin/company/${companyUid}`;
  const swr = useSWR(url, async (url) => {
    try {
      const result = await axios.get(url);
      return result.data;
    } catch (err) {
      throw Error(err);
    }
  });

  return swr;
};

const usePutCompany = () => {
  const putCompany = async (companyUid, payload) => {
    try {
      const url = `/admin/company/${companyUid}`;
      const result = await axios.put(url, payload);
      return result.data;
    } catch (error) {
      throw Error(error);
    }
  };
  return putCompany;
};

const usePostCompany = () => {
  const postCompany = async (payload) => {
    try {
      const url = `/admin/company`;
      const result = await axios.post(url, payload);
      return result.data;
    } catch (error) {
      throw Error(error);
    }
  };
  return postCompany;
};

const useUploadCompanyLogo = () => {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState();
  const putCompany = usePutCompany();
  const { toast } = useToast();
  const uploadCompanyLogo = async (file, companyUid, onUploadSuccess) => {
    try {
      const resizedFile = await resizeImage(file, 600, 600);

      // Get the signed url from our server
      const signature = await axios.get(`admin/company/${companyUid}/logo/upload-signature?fileType=${resizedFile.type}&fileName=${resizedFile.name}`);

      // Upload the file to the signed google url
      await axios.put(signature.data.signedUrl, resizedFile, {
        headers: {
          'Content-Type': resizedFile.type
        },
        withCredentials: false,
        onUploadProgress: (progressEvent) => {
          setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        },
        onError: (error) => {
          setError(error.message || 'An error occurred during upload');
          throw error;
        }
      });
      const newCompany = await putCompany(companyUid, { logo_url: signature.data.publicUrl });
      onUploadSuccess(newCompany);
      setProgress(0);
      setError(null);
    } catch (error) {
      toast({ title: 'Error', description: 'An error occurred during upload', variant: 'destructive' });
      setError(error.message || 'An error occurred during upload');
      setProgress(0);
      throw error;
    }
  };

  return { progress, error, uploadCompanyLogo };
};

export { useCompanies, useCompany, usePutCompany, usePostCompany, useUploadCompanyLogo };
