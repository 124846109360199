import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LinkIcon, Loader2, Mail, MapPin, Phone } from 'lucide-react';

import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from '@/components/ui/breadcrumb';
import { Card, CardContent, CardDescription, CardHeader, CardTitle, CardFooter } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Checkbox } from '@/components/ui/checkbox';
import { Button } from '@/components/ui/button';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Error } from '@/components/ui/error';

import { usePostCompany, useCompanies } from '@/data/company';
import { mutateListOnPost } from '@/helpers/swr';
import { Separator } from '@/components/ui/separator';
import { Textarea } from '@/components/ui/textarea';

const users = [
  {
    firstname: 'Hamid',
    lastname: 'Hajian'
  },
  {
    firstname: 'Tito',
    lastname: 'Morales'
  },
  {
    firstname: 'Jose',
    lastname: 'Sotelo'
  },
  {
    firstname: 'Curran',
    lastname: 'Kennedy'
  },
  {
    firstname: 'Tyson',
    lastname: 'Louth'
  },
  {
    firstname: 'Jake',
    lastname: 'Hildebrandt'
  }
];

const formSchema = z.object({
  company_name: z.string().min(1).max(100),
  username_suffix: z.string().min(1).max(50),
  company_description: z.string().max(200).optional(),
  website: z.string().optional(),
  address: z.string().max(200).optional(),
  phone: z.string().max(50).optional(),
  email: z.string().max(100).optional(),
  users: z.array(z.string()),
  is_market_data: z.boolean(),
  is_market_data_visible: z.boolean(),
  is_v1_estimate: z.boolean(),
  is_v2_estimate: z.boolean()
});

const Add = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiError, setApiError] = useState('');
  const navigate = useNavigate();
  const postCompany = usePostCompany();
  const companies = useCompanies();
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      company_name: '',
      username_suffix: '',
      company_description: '',
      website: '',
      address: '',
      phone: '',
      email: '',
      users: users.map((user) => `${user.firstname}-${user.lastname}`),
      is_market_data: true,
      is_market_data_visible: true,
      is_v1_estimate: false,
      is_v2_estimate: true
    }
  });

  const onSubmit = async (values) => {
    setIsSubmitting(true);
    setApiError('');
    const payload = {
      ...values,
      users: values.users.map((user) => ({
        firstname: user.split('-')[0],
        lastname: user.split('-')[1]
      }))
    };

    try {
      const newCompany = await postCompany(payload);
      await companies.mutate((currentCompanies) => mutateListOnPost(currentCompanies, newCompany), false);
      setIsSubmitting(false);
      navigate(`/company/${newCompany.uid}`);
    } catch (err) {
      setIsSubmitting(false);
      setApiError(err.message);
    }
  };

  const suffix = form.watch('username_suffix');

  return (
    <>
      <Breadcrumb className="mb-4">
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink asChild>
              <Link to="/company">Companies</Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>Add</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <Card className="w-full">
        <CardHeader>
          <CardTitle>Add New Company</CardTitle>
          <CardDescription>Fill out the form to add a new company to Zebel.</CardDescription>
        </CardHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <CardContent className="grid gap-4">
              <div className="grid grid-cols-2 gap-4">
                <FormField
                  control={form.control}
                  name="company_name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Company Name</FormLabel>
                      <FormControl>
                        <Input {...field} placeholder="Enter company name" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="username_suffix"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Username Suffix</FormLabel>
                      <FormControl>
                        <Input {...field} placeholder="Enter username suffix" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="company_description"
                  render={({ field }) => (
                    <FormItem className="col-span-2">
                      <FormLabel>Description</FormLabel>
                      <FormControl>
                        <Textarea {...field} placeholder="Enter company description" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="address"
                  startIcon={<MapPin />}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Address</FormLabel>
                      <FormControl>
                        <Input {...field} placeholder="Enter company address" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="website"
                  startIcon={<LinkIcon />}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Website</FormLabel>
                      <FormControl>
                        <Input {...field} placeholder="Enter company website" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="phone"
                  startIcon={<Phone />}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Phone Number</FormLabel>
                      <FormControl>
                        <Input {...field} placeholder="Enter company phone number" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="email"
                  startIcon={<Mail />}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input {...field} type="email" placeholder="Enter company email" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <Separator />
              <div className="space-y-4">
                <div>
                  <h3 className="font-medium text-lg">Market Data</h3>
                  <p className="text-muted-foreground text-sm">Configure market data preferences for this company</p>
                </div>

                <FormField
                  control={form.control}
                  name="is_market_data"
                  render={({ field }) => (
                    <FormItem className="flex items-center gap-2 space-y-0">
                      <FormControl className="flex items-end">
                        <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                      </FormControl>
                      <FormLabel>Include company data in aggregated market data</FormLabel>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="is_market_data_visible"
                  render={({ field }) => (
                    <FormItem className="flex items-center gap-2 space-y-0">
                      <FormControl className="flex items-end">
                        <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                      </FormControl>
                      <FormLabel>Allow this company to view market data</FormLabel>
                    </FormItem>
                  )}
                />
              </div>
              <Separator />
              <div className="space-y-4">
                <div>
                  <h3 className="font-medium text-lg">Visible Features</h3>
                  <p className="text-muted-foreground text-sm">Configure what features this company can view within Zebel</p>
                </div>
                <FormField
                  control={form.control}
                  name="is_v1_estimate"
                  render={({ field }) => (
                    <FormItem className="flex items-center gap-2 space-y-0">
                      <FormControl className="flex items-end">
                        <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                      </FormControl>
                      <FormLabel>Allow this company to view V1 Estimate Page</FormLabel>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="is_v2_estimate"
                  render={({ field }) => (
                    <FormItem className="flex items-center gap-2 space-y-0">
                      <FormControl className="flex items-end">
                        <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                      </FormControl>
                      <FormLabel>Allow this company to view V2 Estimate Page</FormLabel>
                    </FormItem>
                  )}
                />
              </div>
              <Separator />
              <div className="space-y-4">
                <div>
                  <h3 className="font-medium text-lg">Zebeler Users</h3>
                  <p className="text-sm text-muted-foreground">Configure the Zebeler users for this company</p>
                </div>

                <FormField
                  control={form.control}
                  name="users"
                  render={() => (
                    <FormItem>
                      {users.map((user) => (
                        <FormField
                          key={`${user.firstname}-${user.lastname}`}
                          control={form.control}
                          name={`users`}
                          render={({ field }) => {
                            return (
                              <FormItem key={`${user.firstname}-${user.lastname}`} className="flex flex-row items-center space-x-3 space-y-0 mb-2">
                                <FormControl>
                                  <Checkbox
                                    checked={field.value?.includes(`${user.firstname}-${user.lastname}`)}
                                    onCheckedChange={(checked) => {
                                      return checked
                                        ? field.onChange([...field.value, `${user.firstname}-${user.lastname}`])
                                        : field.onChange(field.value?.filter((value) => value !== `${user.firstname}-${user.lastname}`));
                                    }}
                                  />
                                </FormControl>
                                <div className="leading-tight block">
                                  <FormLabel className="">
                                    {user.firstname} {user.lastname}
                                  </FormLabel>
                                  <FormDescription>
                                    Generated username: {user.firstname}.{suffix}@zebel.io
                                  </FormDescription>
                                </div>
                              </FormItem>
                            );
                          }}
                        />
                      ))}
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              {apiError && <Error message={apiError} />}
            </CardContent>
            <CardFooter className="flex justify-end">
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting && <Loader2 className="animate-spin" />}
                Add Company
              </Button>
            </CardFooter>
          </form>
        </Form>
      </Card>
    </>
  );
};

export default Add;
