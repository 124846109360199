import React from 'react';
import axios from 'axios';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import '@fontsource-variable/inter';
import App from './app';
import { Toaster } from '@/components/ui/toaster';
import './index.css';

import AddCompany from '@/pages/companies/Add';
import Company from '@/pages/companies/Company';
import EditCompany from '@/pages/companies/Edit';
import ListCompanies from '@/pages/companies/List';
import LoginPage from '@/pages/login/Page';
import MsaPremiumDefaultsPage from '@/pages/msaPremiumDefaults/Page';
import StandardFieldsPage from '@/pages/standardFields/Page';
import Users from '@/pages/companies/Users';
import ListDivisions from '@/pages/divisions/List';
import DashboardPage from '@/pages/dashboard/Page';
import { getAuth0Props } from '@/helpers/auth0';
import { getApiUrl } from '@/helpers/api';

axios.defaults.baseURL = getApiUrl();

const router = createBrowserRouter([
  {
    path: '/login',
    element: <LoginPage />
  },
  {
    path: '/',
    element: <App />,
    children: [
      {
        index: true,
        element: <DashboardPage />
      },
      {
        path: 'company',
        children: [
          {
            index: true,
            element: <ListCompanies />
          },
          {
            path: ':companyUid',
            element: <Company />,
            children: [
              {
                index: true,
                element: <EditCompany />
              },
              {
                path: 'users',
                element: <Users />
              }
            ]
          },
          {
            path: 'add',
            element: <AddCompany />
          }
        ]
      },
      {
        path: 'standard-fields',
        element: <StandardFieldsPage />
      },
      {
        path: 'msa-premium-defaults',
        element: <MsaPremiumDefaultsPage />
      },
      {
        path: 'divisions',
        element: <ListDivisions />
      }
    ]
  }
]);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Auth0Provider {...getAuth0Props()}>
    <RouterProvider router={router} />
    <Toaster />
  </Auth0Provider>
);
