import { Card, CardContent } from '@/components/ui/card';
import { Error } from '@/components/ui/error';
import { Loading } from '@/components/ui/loading';
import { useCompanies } from '@/data/company';

const Page = () => {
  const companies = useCompanies();

  if (companies.isLoading && !companies.data) {
    return <Loading />;
  }

  if (companies.error) {
    return <Error message="An error occurred loading companies" />;
  }

  const totalCompanies = companies.data.query.length;
  const v1EstimateOnlyCompanies = companies.data.query.filter(
    (companyUid) => companies.data.content[companyUid].is_v1_estimate && !companies.data.content[companyUid].is_v2_estimate
  );
  const v2EstimateOnlyCompanies = companies.data.query.filter(
    (companyUid) => companies.data.content[companyUid].is_v2_estimate && !companies.data.content[companyUid].is_v1_estimate
  );
  const v1AndV2EstimateCompanies = companies.data.query.filter(
    (companyUid) => companies.data.content[companyUid].is_v1_estimate && companies.data.content[companyUid].is_v2_estimate
  );

  return (
    <div className="grid grid-cols-4 gap-4">
      <Card>
        <CardContent className="p-4">
          <div className="tracking-tight text-sm font-medium">Total Companies</div>
          <div className="text-2xl font-bold">{totalCompanies}</div>
        </CardContent>
      </Card>
      <Card>
        <CardContent className="p-4">
          <div className="tracking-tight text-sm font-medium">V1 Estimate Only</div>
          <div className="text-2xl font-bold">{v1EstimateOnlyCompanies.length}</div>
        </CardContent>
      </Card>
      <Card>
        <CardContent className="p-4">
          <div className="tracking-tight text-sm font-medium">V1 & V2 Estimate</div>
          <div className="text-2xl font-bold">{v1AndV2EstimateCompanies.length}</div>
        </CardContent>
      </Card>
      <Card>
        <CardContent className="p-4">
          <div className="tracking-tight text-sm font-medium">V2 Estimate Only</div>
          <div className="text-2xl font-bold">{v2EstimateOnlyCompanies.length}</div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Page;
